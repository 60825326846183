/* global Stimulus */
import { Controller } from "@hotwired/stimulus";
import sendSentryError from "../common/sentry_error/sentry_error";
export default class extends Controller {
  static targets = [
    "takePhotoButton",
    "startButton",
    "stopButton",
    "nextButton",
    "video",
    "testVideo",
    "recording",
    "canvas",
    "photoReviewFront",
    "photoReviewBack",
    "errorMessageTitle",
    "errorMessage",
    "errorMessageIcon",
    "firstStep",
    "secondStep",
    "thirdStep",
  ];

  connect() {
    if (this.hasVideoTarget && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      this.getCameraStream("environment", this.videoTarget);
    }

    if (this.hasTestVideoTarget && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      this.getCameraStream("user", this.testVideoTarget);
    }

    if (this.hasRecordingTarget && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      this.showStep(1);
      this.getCameraStream("user", this.recordingTargets).then((mediaRecorder) => {
        this.mediaRecorder = mediaRecorder;
        this.handleMediaRecorder();
      });
    }

    if (this.hasNextButtonTarget && this.nextButtonTarget.hasAttribute("disabled")) {
      this.toggleButton(this.nextButtonTarget);
    }

    if (this.hasPhotoReviewFrontTarget && localStorage.getItem("stat_dec_photo_id_front")) {
      this.photoReviewFrontTarget.src = localStorage.getItem("stat_dec_photo_id_front");
      if (localStorage.getItem("stat_dec_photo_id_back")) {
        this.photoReviewBackTarget.src = localStorage.getItem("stat_dec_photo_id_back");
      }
    }
  }

  getCameraStream(facingMode, targets) {
    let constraints = {
      video: {},
      audio: false,
    };

    if (this.isMobileDevice() && facingMode) {
      constraints.video.facingMode = facingMode;
    }

    return navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        this.cameraStream = stream;

        if (!(targets instanceof Array)) {
          targets = [targets];
        }

        targets.forEach((target) => {
          target.srcObject = stream;
          target.play();
        });

        return new MediaRecorder(stream);
      })
      .catch((error) => {
        this.handleCameraError(error);
      });
  }

  handleCameraError(error) {
    const errorMessageMap = {
      NotAllowedError: [
        "Grant Permissions",
        "Camera access was denied. Please grant permissions and try again.",
      ],
      NotFoundError: [
        "No Camera Found",
        "No camera device was found. Please ensure your camera is connected and working.",
      ],
      NotSupportedError: [
        "Browser Not Supported",
        "Your browser doesn't support camera access. Please try with a different browser.",
      ],
      AbortError: ["Camera Access Aborted", "Camera access was aborted. Please try again."],
    };

    const [errorMessageTitle, errorMessage] = errorMessageMap[error.name] || [
      "An Error Occurred",
      `An error occurred while accessing the camera. (${error.name}) Please try again later.`,
    ];

    this.displayError(errorMessageTitle, errorMessage);
    sendSentryError("Stat dec: " + error);
  }

  displayError(title, message) {
    this.errorMessageTitleTarget.textContent = title;
    this.errorMessageTarget.textContent = message;
    this.errorMessageIconTarget.classList.remove("hidden");
    this.toggleButton(this.takePhotoButtonTarget);
  }

  isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  takePhoto() {
    const context = this.canvasTarget.getContext("2d");

    const videoWidth = this.videoTarget.videoWidth;
    const videoHeight = this.videoTarget.videoHeight;
    const videoAspectRatio = videoWidth / videoHeight;

    this.canvasTarget.width = 800;
    this.canvasTarget.height = 800 / videoAspectRatio;

    const idSide = window.location.pathname.split("/")[4];

    context.drawImage(this.videoTarget, 0, 0, this.canvasTarget.width, this.canvasTarget.height);
    const dataURL = this.canvasTarget.toDataURL("image/jpeg", 0.9);
    localStorage.setItem(`stat_dec_photo_id_${idSide}`, dataURL);
  }

  sendPhoto() {
    const dataURLFront = localStorage.getItem("stat_dec_photo_id_front");
    const dataURLBack = localStorage.getItem("stat_dec_photo_id_back");
    const formData = new FormData();
    formData.append("photo_id[photo_id_front]", this.dataURItoBlob(dataURLFront));
    formData.append("photo_id[photo_id_back]", this.dataURItoBlob(dataURLBack));
    this.uploadData("upload_photo_id", formData)
      .then(() => {
        localStorage.removeItem("stat_dec_photo_id_front");
        localStorage.removeItem("stat_dec_photo_id_back");
        window.location.href =
          "/next_of_kin/statutory_declaration/practice/" + this.data.get("funeralidValue");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  uploadData(endpoint, data) {
    return fetch(
      "/next_of_kin/statutory_declaration/" + this.data.get("funeralidValue") + `/${endpoint}`,
      {
        method: "PATCH",
        body: data,
        headers: {
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')?.content,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  handleMediaRecorder() {
    this.chunks = [];
    this.mediaRecorder.start();

    this.mediaRecorder.ondataavailable = (event) => {
      this.chunks.push(event.data);
    };

    this.mediaRecorder.onstop = () => {
      if (this.cameraStream) {
        this.cameraStream.getTracks().forEach((track) => track.stop());
      }

      const blob = new Blob(this.chunks, { type: "video/mp4" });
      const formData = new FormData();
      formData.append("video_and_signature_recordings[declaration_video]", blob);

      if (this.flowFinished) {
        this.getSignaturePad()
          .getSignatureData()
          .then(this.blobToBase64)
          .then((base64String) => {
            formData.append("video_and_signature_recordings[signature_blob]", base64String);

            this.uploadData("upload_video_and_signature", formData)
              .then(() => {
                window.location.href =
                  "/next_of_kin/statutory_declaration/success/" + this.data.get("funeralidValue");
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          });
      }
    };
  }

  handleStopMediaRecorder() {
    this.flowFinished = true;
    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
    }
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0) byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  blobToBase64(blob) {
    return new Promise((resolve, _reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(blob);
    });
  }

  showStepEvent(event) {
    const step = event.currentTarget.dataset.step;
    this.showStep(parseInt(step, 10), event);
  }

  showStep(step, event = null) {
    this.clearStep();

    switch (step) {
      case 1:
        this.firstStepTarget.classList.remove("hidden");
        break;
      case 2:
        this.secondStepTarget.classList.remove("hidden");
        this.getSignaturePad().show();
        break;
      case 3:
        if (this.validateSignature(event)) {
          this.thirdStepTarget.classList.remove("hidden");
          this.handleStopMediaRecorder();
        } else {
          this.showStep(2);
        }
    }
  }

  clearStep() {
    this.firstStepTarget.classList.add("hidden");
    this.secondStepTarget.classList.add("hidden");
    this.thirdStepTarget.classList.add("hidden");
  }

  toggleButton(button) {
    if (button instanceof Event) {
      button = this.nextButtonTarget;
    }

    const checkbox = document.getElementById("tos-checkbox");

    if (checkbox && checkbox.checked) {
      this.enableButton(button);
    } else {
      this.disableButton(button);
    }
  }

  enableButton(button) {
    button.classList.add(
      "bg-forest-600",
      "light:bg-navy-700",
      "text-white",
      "hover:bg-mint-500",
      "hover:text-forest-600"
    );
    button.classList.remove(
      "bg-gray-300",
      "cursor-not-allowed",
      "opacity-50",
      "text-gray-800",
      "hover:bg-gray-400",
      "pointer-events-none"
    );
  }

  disableButton(button) {
    button.classList.add(
      "bg-gray-300",
      "cursor-not-allowed",
      "opacity-50",
      "text-gray-800",
      "hover:bg-gray-400",
      "pointer-events-none"
    );
    button.classList.remove(
      "bg-forest-600",
      "light:bg-navy-700",
      "text-white",
      "hover:bg-mint-500",
      "hover:text-forest-600"
    );
  }

  getSignaturePad() {
    const signaturePadElement = document.querySelector("[data-controller='signature_pad']");
    if (signaturePadElement) {
      const signaturePadController = Stimulus.getControllerForElementAndIdentifier(
        signaturePadElement,
        "signature_pad"
      );

      if (signaturePadController) {
        return signaturePadController;
      }
    }
  }

  validateSignature(event) {
    const signaturePad = this.getSignaturePad();
    if (signaturePad) {
      if (signaturePad.sigPad.isEmpty()) {
        event.preventDefault();
        document.querySelector(".signature-error").classList.remove("hidden");
        return false;
      } else {
        document.querySelector(".signature-error").classList.add("hidden");
        return true;
      }
    }
  }
}
